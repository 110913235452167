import React, {useEffect, useState, useContext} from 'react'
import { fetchDataContext, StopwatchValueContext, isPostContext, IsRoundContext, MaxStopwatchValueContext, OldCoefsListContext } from '../../../../context/allContext'
import classes from './GameSection.module.css'

const Stopwatch = (fakeCoefs) => {
    const [StopwatchDisplay, setStopwatchDisplay] = useState('none')
    const [stopwatchChange, setStopwatchValue] = useState({value:'1.00', color:'white'})

    const {isRound, setIsRound} = useContext(IsRoundContext)
    const {isPost, setIsPost} = useContext(isPostContext)
    const {fetching, setFetch} = useContext(fetchDataContext)
    const {stopwatchValue, getStopwatchValue} = useContext(StopwatchValueContext)
    const {maxCoef, changeMaxCoef} = useContext(MaxStopwatchValueContext)
    const {oldCoefsList, setOldCoefsList} = useContext(OldCoefsListContext)

    function generateRandomFloat(min, max) {
        return (Math.random() * (max - min) + min).toFixed(2);
      }
    
    const checkFakeCoefs = () => {
        if (fakeCoefs.fakeCoefs.fakeCoefs && fakeCoefs.fakeCoefs.fakeCoefs.length > 0){
            let firstOne = fakeCoefs.fakeCoefs.fakeCoefs.shift()
            return firstOne.coef
        } else{
            return generateRandomFloat(1.00, 5.55)
        }
    }

    function saveOldCoef(coef){
        fetch('/add_new_old_coef',{
            method: 'PUT',
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8',
                'X-CSRFToken':fetching
            },
        body: JSON.stringify({coef: coef})
        })
    }

    useEffect(() => {
        if (isRound){
            const coef = Number(checkFakeCoefs())
            
            
            

            setStopwatchDisplay('block')
            changeMaxCoef(coef)

            

            var interval = setInterval(ChangeStopwatch, 100)
            var flag1 = 0
            var intVal = 100;
            
            function div(val, by){
                return (val - val % by) / by;
            }

            


            function ChangeStopwatch () {
        
                setStopwatchValue(preValue => {
                    const numValue = Number(Number(preValue.value).toFixed(2))

                    if ( numValue < Number(coef.toFixed(2))){
                        let new_one = (numValue+0.01).toFixed(2)


                        if (div(new_one, 0.5) > flag1 ) {
                            flag1 = div(new_one, 0.5)
                            intVal = Math.round( intVal * 0.8 )

                            clearInterval(interval)
                            interval = setInterval(ChangeStopwatch, intVal )
                        }

                        
                        return {value: new_one, color: preValue.color}
                    }else{
                        if(oldCoefsList.length == 33){
                            
                            setOldCoefsList(preVal => { 
                                preVal.pop()
                                return [{id: Number(preVal[0].id) +1, coef:numValue}, ...preVal]
                            })

                        }else{
                            setOldCoefsList(preVal => { 
                                return [{id: Number(preVal[0].id) +1, coef:numValue}, ...preVal]
                            })
                        }
                        
                        saveOldCoef(numValue)
                        clearInterval(interval)
                        
                        setIsRound(false)
                        setIsPost(true)
                        
                        
                        return {value: preValue.value, color : 'red'}
                    }
                })
            }



        }

    }, [isRound])


    useEffect(() =>{
        if (!isPost){
            setStopwatchDisplay('none')
            changeMaxCoef(false)
            setStopwatchValue({value: '1.00', color:'white'})
            
        }
    },[isPost])

  return (
    <div className={classes.coefs_board} style={{display: StopwatchDisplay, color: stopwatchChange.color}}>
        
        <span id="stopwatch">{stopwatchChange.value}</span>x
    
    </div>
  )
}

export default Stopwatch